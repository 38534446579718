import React from "react";
import { Link } from "react-router-dom";
import { RouteEnum } from "common/constant";

function Footer() {
  return (
    <footer className="pt-16">
      <div className="bg-gradient-to-r from-PrimaryDark to-PrimaryLight p-5 text-White md:p-10">
        <div className="w-full mx-auto gap-5 flex justify-between flex-col md:flex-row md:w-11/12">
          <h6 className="text-center">
            © {new Date().getFullYear()} Oyariyders Inc. All rights reserved.
          </h6>
          <div className="flex justify-between gap-5 flex-col text-center md:gap-10 md:flex-row">
            <Link to={RouteEnum.PRIVACY} className="hover:underline">
              Privacy Policy
            </Link>
            <Link to={RouteEnum.SUPPORT} className="hover:underline">
              Support
            </Link>
            <Link to={RouteEnum.DELETE_DATA} className="hover:underline">
              Delete my Data
            </Link>
            <Link to={RouteEnum.COPYRIGHT} className="hover:underline">
              Copyright
            </Link>
            <Link to={RouteEnum.GUIDE} className="hover:underline">
              Guide
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
