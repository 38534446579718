export const RouteEnum = {
  HOME: "/",
  ABOUT: "/about",
  DRIVER: "/driver",
  PRIVACY: "/privacy",
  COPYRIGHT: "/copyright",
  SUPPORT: "/support",
  DELETE_DATA: "/support/delete-account/",
  GUIDE: "/guide",
};
